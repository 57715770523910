import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Location } from '@reach/router'

import topLeft from '../images/header/top-left.svg'
import topCenter from '../images/header/top-center.svg'
import topRight from '../images/header/top-right.svg'
import bottomLeft from '../images/header/bottom-left.svg'
import separator from '../images/list-separator.svg'
import logo from '../images/logos/intercept.svg'

const Header = (props) => (

  <Location>
  {({location}) => (
    <>
    <header className={location.pathname === '/' ? 'site--header home' : 'site--header'}>
      <div className="svg-wrapper">
        <img className="svg top-left" src={topLeft} alt="page-decoration"></img>
        <img className="svg top-center" src={topCenter} alt="page-decoration"></img>
        <img className="svg top-right" src={topRight} alt="page-decoration"></img>
        <img className="svg bottom-left" src={bottomLeft} alt="page-decoration"></img>
        <div className="site--wrapper">
          <nav className="site--nav">
            <ul>
              <li><Link to="/"><img src={logo} alt="intercept" /></Link></li>
              <li><a href="/why/">Why</a></li>
              <li><a href="/how/">How</a></li>
              <li><a href="/who/">Who</a></li>
              <li><a href="/news/">News</a></li>
              <li><a href="/contact/">Contact</a></li>
              <li><a href="/faq/">FAQ</a></li>
            </ul>
          </nav>
          <section className="site--tagline">
            <h1>Library events reimagined.</h1>
            <h2>Track. Engage. Evolve.</h2>
          </section>
          <section className="site--feature-list">
            <ul>
              <li>Event Management</li>
              <li><img src={separator} alt="" /></li>
              <li>Room &amp; Equipment Reservations</li>
              <li><img src={separator} alt="" /></li>
              <li>Customer Tracking</li>
            </ul>
          </section>
        </div>
      </div>
    </header>
    <div className="top-ruffle"></div>
    </>
  )}
  </Location>

  
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
